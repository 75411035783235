import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/Layout'
import BannerLanding from '../../components/BannerLanding'
import ImageGallery from '../../components/accommodations/ImageGallery'
import Description from '../../components/accommodations/Description'
import Agenda from '../../components/accommodations/Agenda'
import PriceTable from '../../components/accommodations/PriceTable'
import Related from '../../components/accommodations/Related'

import getData from '../../messages/casa-messages'
import Facillities from '../../components/accommodations/Facilities'

const Casa = () => {
  const casaData = getData()

  return (
    <Layout>
    <Helmet
        title="Casa do Sóter"
        meta={[
            { name: 'description', content: 'Hospedagem na Lapinha da Serra - MG' },
            { name: 'keywords', content: 'Lapinha da Serra, Lapinha, hospedagem, acomodação, acomodações, pousada, Serra do Cipó, Minas Gerais, turismo, viagem' },
        ]}
    />

    <BannerLanding backgroundImage={casaData.coverImage} title={casaData.title}/>

    <div id="main">
      <Facillities facilities={casaData.facilities} />

      <Description description={casaData.description} infos={casaData.infos} />
      
      <ImageGallery accommodation={casaData.id}/>

      <Agenda accommodation={casaData.title} />

      <PriceTable accommodation={casaData}/>

      {
        casaData.related.map(accommodation => <Related accommodation={accommodation}/>)
      }
    </div>

    </Layout>
  )
}

export default Casa